<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 测评题目 -->
        <div class="answerbox">
            <div class="answercon">
                <div class="title">霍兰德职业测试</div>
                <div class="anbox">
                    <el-progress :text-inside="true" :stroke-width="strokewidth" :percentage="percentage" :show-text="false"></el-progress>
                    <div v-if="answershow">
                        <div class="number">第{{questionItem.Sort}}题，共{{this.questionNumber}}题</div>
                        <div class="topic">{{questionItem.Sort}}、{{questionItem.Question}}</div>
                        <div class="angroup">
                            <div class="anitem" @click.prevent="answerbtn(item.AnswerValue)" v-for="(item, index) in questionItem.AnswerList" :key="index">{{item.AnswerItem}}</div>
                        </div>
                    </div>
                    <div class="complete" v-else>
                        <i class="iconfont">&#xe607;</i>
                        <div class="tit">您已完成所有题目</div>
                        <div class="submit" @click.prevent="submitTest()">提交测评</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 温馨提示 -->
        <div class="anlayer" :class="layerclass" v-if="layershow">
            <div class="anmark" :class="layerboxclass">
                <div class="tt">温馨提示</div>
                <div class="xx">1、问卷选项没有“对”与“错”之分。请选择你是怎样做的，而不是选择你认为哪样更好。</div>
                <div class="xx">2、测试的目的是反映最真实的自己。请最大程度放松下来，选择更接近你平时的感受或行为的那项。</div>
                <div class="xx">3、共60题，约10分钟可以做完。</div>
                <div class="btn" @click.prevent="closebtn()">明白了，开始吧</div>
                <div class="close" @click.prevent="closebtn()"><i class="iconfont">&#xe620;</i></div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from '@/api/config';
import { getStore } from '@/utils/utils';
const _ = require('lodash');
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            answershow:false,
            strokewidth:16,
            percentage:0,
            answerindex:0,
            layershow:true,
            layerclass:'layershow',
            layerboxclass:'layerboxshow',
            answer: null,
            AnswerList: [],
            questionNumber:0,
            questionMain: [],
            questionItem: {},
        }
    },
    mounted(){
        this.initAnswer();//初始化题目
    },
    methods:{
        // 答题
        answerbtn:_.debounce(function(value) {
            this.AnswerList.push(value);
            if(this.answerindex<(this.questionNumber-1)){
                this.answerindex++;
                this.questionItem = this.questionMain[this.answerindex];
                this.percentage+=(100 / (this.questionNumber-1));
            }
            else{
                //完成
                this.answershow = false;
            }
        }, 200, {
            'leading': true, //在延迟开始前立即调用事件
            'trailing': false, //在延时结束后不调用,保证事件只被触发一次
        }),

        // 提交测评
        submitTest(){
            this.$ls.set("testAnswer", this.AnswerList);
            let temp = getStore('LoginData');
            if (!temp) {
                this.$message.info('请先登录！');
                return;
            }
            API.Career.HollandTest({ UserId: JSON.parse(temp).UserId, AnswerList: this.AnswerList}).then(res => {
                this.$router.push('/holland-result');
            }).catch(err => {
                this.$message.error(err);
            });
        },

        // 关闭提示
        closebtn(){
            this.layerclass='layerhide';
            this.layerboxclass="layerboxhide";
            let st = setTimeout(()=>{
                this.layershow=false;
                clearTimeout(st);
            },300);
        },
        
        //初始化题目
        initAnswer(){
            this.AnswerList = [];
            API.Career.HollandQuestionList().then(res => {
                this.questionNumber = res.data.length;
                this.questionMain = res.data;
                this.questionItem = this.questionMain[this.answerindex];
            }).catch(err => {
                this.$message.error(err);
            })
        }
    }
}
</script>
<style lang="less" scoped>
.answerbox{
    max-width: 1420px;
    margin: auto;
    .answercon{
        margin: 20px 20px 30px;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        min-height: 500px;
        user-select: none;
        .title{
            color: #555;
            font-size: 26px;
            text-align: center;
        }
        .anbox{
            margin: 0px 50px;
            .number{
                color: #666;
                font-size: 14px;
                height: 40px;
                line-height: 40px;
            }
            .topic{
                color: #555;
                font-size: 18px;
                font-weight: bold;
                margin-top: 50px;
            }
            .angroup{
                margin-top: 10px;
                .anitem{
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    text-indent: 28px;
                    background-color: rgba(var(--themecolor),0.05);
                    margin-top: 15px;
                    border-radius: 3px;
                    color: rgb(var(--themecolor));
                    font-size: 16px;
                    border: 1px solid #ddd;
                    font-weight: bold;
                    letter-spacing: 1px;
                    cursor: pointer;
                    transition:all 0.2s;
                    -webkit-transition:all 0.2s;
                }
                .anitem:hover{
                    background-color: rgba(var(--themecolor),0.1);
                }
                .anitem:active{
                    border: 1px solid rgba(var(--themecolor),1);
                    background-color: rgba(var(--themecolor),0.1);
                }
            }
            .complete{
                margin-top: 50px;
                .iconfont{
                    text-align: center;
                    display: block;
                    font-size: 80px;
                    color: #53af53;
                }
                .tit{
                    text-align: center;
                    font-size: 22px;
                    color: #666;
                    margin-top: 10px;
                    letter-spacing: 5px;
                }
                .submit{
                    width: 180px;
                    height: 46px;
                    line-height: 46px;
                    margin: 50px auto 0px;
                    color: #fff;
                    background-color: rgba(var(--themecolor),0.85);
                    border-radius: 30px;
                    text-align: center;
                    font-size: 18px;
                    letter-spacing: 2px;
                    cursor: pointer;
                    transition: all 0.2s;
                }
                .submit:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
.anlayer.layershow{
    animation:layershow 0.3s ease 0s 1;
    -webkit-animation:layershow 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
.anlayer.layerhide{
    animation:layerhide 0.3s ease 0s 1;
    -webkit-animation:layerhide 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    @keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
}
.anlayer{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
    .anmark.layerboxshow{
        animation:layerboxshow 0.3s ease 0s 1;
        -webkit-animation:layerboxshow 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxshow {
            from {top: calc(50% - 220px);}
            to {top: calc(50% - 200px);}
        }
        @keyframes layerboxshow {
            from {top: calc(50% - 220px);}
            to {top: calc(50% - 200px);}
        }
    }
    .anmark.layerboxhide{
        animation:layerboxhide 0.3s ease 0s 1;
        -webkit-animation:layerboxhide 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxhide {
            from {top: calc(50% - 200px);}
            to {top: calc(50% - 220px);}
        }
        @keyframes layerboxhide {
            from {top: calc(50% - 200px);}
            to {top: calc(50% - 220px);}
        }
    }
    .anmark{
        max-width: 700px;
        height: 400px;
        background-color: #fff;
        border: 1px solid #ddd;
        position: relative;
        top: calc(50% - 200px);
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        .tt{
            color: #666;
            font-size: 18px;
            letter-spacing: 2px;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .xx{
            color: #666;
            font-size: 14px;
            margin: 15px 20px;
        }
        .btn{
            width: 200px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            letter-spacing: 2px;
            background-color: rgba(var(--themecolor),0.7);
            margin: 80px auto 0px;
            border-radius: 3px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }
        .btn:hover{
            cursor: pointer;
            background-color: rgba(var(--themecolor),0.8);
        }
        .close{
            position: absolute;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            top: 0px;
            right: 0px;
            z-index: 1;
            font-size: 18px;
            color: #999;
        }
        .close:hover{
            cursor: pointer;
            color: rgb(var(--themecolor));
        }
    }
}
</style>
<style>
.answerbox .answercon .anbox .el-progress{
    margin-top: 30px;
}
.answerbox .answercon .anbox .el-progress-bar__outer{
    background-color: rgb(217, 219, 221) !important;
}
.answerbox .answercon .anbox .el-progress-bar__inner{
    background-color: rgba(var(--themecolor),0.5) !important;
    background-image: linear-gradient(to right, rgba(var(--themecolor),0), rgba(var(--themecolor),1));
}
.answerbox .answercon .anbox .el-progress-bar__innerText{
    color: #f7f6f6 !important;
    font-size: 12px;
}
</style>